/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import UtterancesComments from '../../components/utterances';
function _createMdxContent(props) {
  const _components = Object.assign({
    ol: "ol",
    li: "li",
    em: "em",
    code: "code",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Purchase SSL certificate, ", React.createElement(_components.em, null, "e.g. bought mine through Namecheap")), "\n", React.createElement(_components.li, null, "Generate CSR and key on server", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "ssh in"), ", ", React.createElement(_components.code, null, "install SSL"), ", ", React.createElement(_components.code, null, "run SSL cmd"), " and ", React.createElement(_components.code, null, "download output")), "\n", React.createElement(_components.li, null, "example SSL command: ", React.createElement(_components.code, null, "openssl req -new -newkey rsa:2048 -nodes -out yoursite_com.csr -keyout yoursite_com.key -subj \"/CN=yoursite.com\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stackoverflow.com/questions/35409127/how-to-create-a-csr-file-for-azure-web-app"
  }, "Stackoverflow: How to create a csr file for azure web app")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Verify ssl with csr, ", React.createElement(_components.em, null, "e.g. copy csr and paste into namecheap")), "\n", React.createElement(_components.li, null, "Activate ssl by selecting method, ", React.createElement(_components.em, null, "e.g. I chose whois email which forwards onto your actual email")), "\n", React.createElement(_components.li, null, "Download package which contains the files: ", React.createElement(_components.code, null, ".ca-bundle"), ", ", React.createElement(_components.code, null, ".crt"), ", ", React.createElement(_components.code, null, ".p7b")), "\n", React.createElement(_components.li, null, "Azure needs a ", React.createElement(_components.code, null, "pfx"), " (or ", React.createElement(_components.code, null, "pkcs#12"), ") file, use online tool to convert ", React.createElement(_components.code, null, ".p7b"), ", ", React.createElement(_components.code, null, ".key"), ", and any password to a ", React.createElement(_components.code, null, "pfx"), ", ", React.createElement(_components.a, {
    href: "https://decoder.link/converter"
  }, "decoder.link/converter"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "At the link above use ", React.createElement(_components.code, null, "PEM"), " to ", React.createElement(_components.code, null, "PKCS#12")), "\n", React.createElement(_components.li, null, "OpenSSL also generated ", React.createElement(_components.code, null, ".key"), " file when running the command before, locate this file and upload it to the converter page as the key file"), "\n", React.createElement(_components.li, null, "Upload the ", React.createElement(_components.code, null, ".cert"), " file from the package for Certificate file"), "\n", React.createElement(_components.li, null, "Upload the ", React.createElement(_components.code, null, ".ca-bundle"), " file from the package for the Bundle file"), "\n", React.createElement(_components.li, null, "Enter a password which will be used when uploading the ", React.createElement(_components.code, null, "PFX"), " file"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Go to Azure, go to the app, then TLS/SSL settings, click Private key certificates (", React.createElement(_components.code, null, ".pfx"), ") then Upload certificate"), "\n", React.createElement(_components.li, null, "Upload generated pfx file and enter the same password as in step 6.5"), "\n", React.createElement(_components.li, null, "Done!"), "\n"), "\n", React.createElement(UtterancesComments));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
